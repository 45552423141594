body{
  font-family: 'Poppins', sans-serif, 'Hanuman', serif;
}

#lottie-player svg > g > g:nth-child(3) > g:nth-child(14) > path {
  fill-opacity: 0.1 !important;
}

/* #bg-pattern {
  background-image: url("/static/pattern-web.png");
  background-size: cover;
  background-repeat: no-repeat;
} */

.lang-flag-en {
  /* background-image: url('/static/flag-en.svg'); */
  background-position: center center;
  background-size: cover;
}
.lang-flag-kh {
  /* background-image: url('/static/flag-kh.svg'); */
  background-position: center center;
  background-size: cover;
}
#sortbox:checked ~ #sortboxmenu {
  opacity: 1;
}